.tip-selector {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 56% auto;
  grid-auto-rows: auto 595px;
  grid-gap: 20px;
  grid-template-areas:
    "tip-selector__body tip-selector__header--right-column"
    "tip-selector__body tip-selector__detail-and-googlemap-container";
  margin: 0 auto;
  padding: 0px 20px 20px 20px;
  background-color: #ffffff;
}

.tip-selector__title.modal-header {
  padding-bottom: 0px;
}

.tip-selector__header {
  display: flex;
  justify-content: space-between;
  width: 50vw;
}

.tip-selector__header--right-column {
  grid-area: tip-selector__header--right-column;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  display: grid;
  grid-template-columns: 23% 23% 20% 34%;
  height: 75px;
  overflow: visible;
  z-index: 999;
}

.tip-selector__search-location-input,
.tip-selector__search-type,
.tip-selector__search-tags,
.tip-selector__search-radius {
  width: 100%;
  font-size: 10px;
  color: #363648;
}

.tip-selector__seach-location {
  display: flex;
  flex-direction: row;
}

.tip-selector__search-location-input {
  height: 38px;
  width: 100%;
  border: 1px solid #cccccc;
  border-right: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 2px 8px;
}

.tip-selector__search-suggestions {
  margin: 0;
  list-style-type: none;
  font-size: 13px;
  background: white;
  padding: 3px 3px 3px 3px;
  width: 20vw;
  cursor: pointer;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}

.tip-selector__search-suggestions:hover {
  background: #f3f9fd;
}

.tip-selector__body {
  grid-area: tip-selector__body;
}

.tip-selector__table {
  width: 100%;
}

.tip-selector__table-header {
  color: #868691;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 5% 23% 34% 25%;
  grid-auto-rows: minmax(20px, auto);
  grid-gap: 5px;
  margin: 0 auto;
  padding: 5px;
}

.tip-selector__table-body {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  height: 100%;
}

.tip-selector__detail-and-googlemap-container {
  grid-area: tip-selector__detail-and-googlemap-container;
}

.tip-selector__text-input {
  border-top-right-radius: 4px;
  border-right: solid 1px #c6c6c6;
  border-bottom-right-radius: 4px;
  margin-top: 8px;
  margin-left: 0px;
  width: 200px;
}

.tip-selector__paginate-btn {
  margin-top: 1%;
}
