.pill-button {
  width: 110px; 
  height: 32px;
  margin-right: 10px;
  color: #595c6c;
  background: #ddedf6;
  border: none;
  border-radius: 26.5px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.08);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
}

.pill-button:hover {
  background: #b7daed;
}

.pill-button:focus{
  outline: none;
}

.pill-button:disabled{
  background:#BFC0C6;
  color: #FFFFFF;
  font-weight: 400;
  }