.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}

.header__home-btn {
  height: 40px;
}

.header__container {
  display: flex;
  align-items: center;
}

.header__notification-icon,
.header__avatar-woman,
.header__log-out {
  margin: 2px 2px 2px 15px;
}

.header__avatar-woman{
  margin-right: 5px;
  border-radius: 50%;
}

.header__notification-icon,
.header__avatar-woman {
  height: 30px;
}

.header__logout-btn{
width: 65px;
height: 25px;
margin-left: 15px; 
}