.trip-component__container{
  display:flex;  
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.trip-component__action{
  color: #525258;
  font-size: 14px;
  font-weight: bold; 
  line-height: 20px;
  margin: 3px;
}

.trip-component__icon{
  width: 75px; 
  height: 20px;
  margin: 0px;
  border-radius: 7px;
  box-shadow: 0px 1px 3px rgba(0,0,0,0.08);
  background-color: lightblue;
  font-size: 12px;
  text-align: center;
  margin: 3px;
}
