.bulk-page .row > div {
    padding-left: 15px;
    padding-right: 15px;
}

.bulk-page div.dropdown-menu {
    max-width: 100% !important;
}

.bulk-page .dropdown-item {
    font-size: 1rem;
}

.bulk-page .tab-content {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
}

.bulk-page #preview-messages {
    max-height: 600px;
    overflow-y: scroll;
}

.bulk-page #preview-messages .card {
    margin: 12px 0;
}