a#conversation-tabs-tab-task.nav-item.nav-link.active,
a#conversation-tabs-tab-profile.nav-item.nav-link.active,
a#conversation-tabs-tab-tips.nav-item.nav-link.active,
a#conversation-tabs-tab-analysis.nav-item.nav-link.active {
    color: #363648;
    font-size: 20px;
    font-weight: 900;
    line-height: 25px;
    border: none;
    background-color: #F9FAFB;
    border-bottom: 4px red solid;
}

a#conversation-tabs-tab-profile.nav-item.nav-link,
a#conversation-tabs-tab-task.nav-item.nav-link,
a#conversation-tabs-tab-tips.nav-item.nav-link,
a#conversation-tabs-tab-analysis.nav-item.nav-link {
    color: #868691;
    font-size: 19px;
    font-weight: 900;
    line-height: 25px;
    padding: 8px 12px;
}

.open-conversation-tab {
    padding: 0px !important;
}

