.itinerary-details {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(15px, auto);
  grid-column-gap: 40px;
  grid-row-gap: 0px;
  width: 100%;
  margin: 0 auto;
  padding: 2vw;
  background-color: #f9fafb;
}

.itinerary-details__header {
  grid-column: 1/5;
}

.itinerary-details__itinerary-container {
  grid-column: 1/3;
}

.itinerary-details__travellers-container {
  grid-column: 3/5;
}

.itinerary-details__timeline {
  grid-column: 1/4;
  grid-row: 3/10;
  overflow: auto; 
}

.itinerary-details__task-list {
  grid-column: 4/5;
  grid-row: 3/4;
  height: auto;
}

.itinerary-details__activity-log {
  grid-column: 4/5;
}

