.template-card a:hover {
  text-decoration: none;
}

.template-card:hover {
  opacity: 0.7;
}

.template-card__content {
  position: relative;
  height: 165px; 
  overflow: hidden; 
  border-radius: 7px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  background-color: white;
  color: black;
}

.template-card__is-draft {
  opacity: 0.6;
}

.template-card__photo {
  position: relative;
}

.template-card__photo img {
  width: 100%;
  max-height: auto;
  overflow: hidden;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.template-card__draft {
  position: absolute;
  top: 8px;
  right: 16px;
  width: 40px;
  border-radius: 25px;
  background-color: #d3d3d4;
  font-size: 14px;
  text-align: center;
}

.template-card__type {
  margin: 5px 0px 0px 5px;
  color: #868691;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.4px;
}

.template-card__name {
  margin-left: 5px;
  margin-bottom: 5px;
  overflow: hidden;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-overflow: ellipsis;
}

.template-card__tag-container {
  position: absolute;
  bottom: 5px;
  display: flex;
  max-width: 240px;
  overflow: hidden; 
  margin-left:5px;
  padding-bottom: 5px;
}

.template-card__tag {
  margin-right: 5px;
  padding: 0px 2px 2px 2px;
  border-radius: 7px;
  background-color: #f9fafb;
  border: 1px solid #eaecee;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  font-size: 10px;
}
