.traveller-card {
  display: grid;
  grid-template-columns: 30% 30% 20% 20%;
  margin-bottom: 10px;
  padding: 3px 7px;
  border-radius: 7px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  background-color: #f3f9fc;
}

.traveller-card__title {
  color: #868691;
  font-size: 12px;
  font-weight: 700;
}

.traveller-card__text {
  color: #363648;
  font-size: 14px;
}

.traveller-card__btns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.traveller-card__btn {
  width: 30px;
  height: 30px;
  padding: 10px;
  margin-left: 5px;
  border: none;
  background-repeat: no-repeat;
  background-color: #f3f9fc;
  background-size: cover;
}

.traveller-card__btn:hover {
  border-radius: 7px;
  background-color: #ddedf6;
}
.traveller-card__btn--edit {
  background-image: url(../../../../../images/IconTemplates.png);
}

.traveller-card__btn--disconnect {
  background-image: url(../../../../../images/unplugged.png);
  background-size: 25px;
  background-position: center;
  opacity: 0.6;
}
