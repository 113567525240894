.kpi-type-header {
    font-size: 24px;
    margin: 20px 0px;
}

.proactive-flow-table {
    width: 100%;
}

.proactive-flow-div {
    overflow:auto;
    width:100%;
    height:130%
}

th {
    min-width: 75px !important;
}

tr, td {
    min-height: 35px !important;
}