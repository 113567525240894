.preferences-individual-itinerary__container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: 70px;
}

.preferences-individual-itinerary__input {
  min-width: 300px;
  padding: 0px;
  font-size: 10px;
}

.preferences-individual-itinerary__btn {
  height: 38px;
  width: 70px;
  margin-left: 10px;
  font-size: 12px;
}

.preferences-individual-itinerary__list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.preferences-individual-itinerary__tag {
  height: 22px;
  margin: 0px 5px 5px 5px;
  padding: 3px 7px;
  border-radius: 7px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  background: #ddedf6;
  color: #363648;
  font-size: 10px;
  line-height: 14px;
}
