.template-day {
  padding: 2%;
  border-radius: 7px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  background: white;
}

.template-day__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
}

.template-day__header--text {
  border-bottom: 3px solid #f38251;
  text-align: left;
  color: #363648;
  font-size: 20px;
  font-weight: 900;
  line-height: 28px;
}

.template-day__select-whatsapp {
  color: #363648;
  font-size: 12px;
}

.template-day__message {
  color: #363648;
  font-size: 14px;
}

.template-day__status-message {
  border-bottom: 3px solid #f38251;
    color: #363648;
    font-size: 16px;
    font-weight: 900;
    line-height: 28px;
    width: max-content;
    text-align: center;
    margin-top: 2%;
}

.template-day__footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.template-day__time {
  width: 40%;
  margin-top: 35px;
  color: #363648;
  font-size: 14px;
}

.react_times_button {
  width: 60%;
}
.react_times_button.time_picker_preview {
  box-shadow: none;
}

.react_times_button .wrapper {
  margin-top: 15px;
  border-radius: 25px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  color: #363648;
  font-size: 14px;
}

.time_picker_modal_container {
  text-align: -webkit-center;
}

.time_picker_modal_header,
.picker_pointer,
.picker_center,
.pointer_drag.draggable {
  background-color: #020360 !important;
}
