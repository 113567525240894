.analysis-form {
    width: -webkit-fill-available;
    padding: 10px;
}

.analysis-title {
    color: #8a8a95;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 5px;
}

.analysis-block {
    margin-bottom: 10px;
}

.analysis-textarea {
    width: -webkit-fill-available;
    border: 1px solid hsl(0, 0%, 80%);
    padding: 5px;
}

.analysis-input {
    width: inherit;
    display: inline;
    border: 1px solid hsl(0, 0%, 80%);
}

.analysis-checkbox {
    text-align: inherit;
}

.analysis-checkbox > label > input {
    margin-right: 5px;
}

input,
input:active,
input:focus {
    outline: none;
}

.save-analysis-open {
    position: absolute;
    right: 52%;
    top: -70px;
}

.save-analysis-closed {
    position: absolute;
    right: 105%;
    top: -70px;
}

.save-analysis-open input, .save-analysis-closed input {
    color: white;
    background-color: #f38251;
    border: 1px solid #f38251;
    border-radius: 5px;
    padding: 5px 15px;
    font-size: 16px;
    margin-top: 3px;
}

.analysis-label {
    margin-left: 3px;
    vertical-align: middle;
}