.add-itinerary-modal__body {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-itinerary-modal__input {
  width: 400px;
  margin: 0px 0px 20px 0px;
  font-size: 14px;
}

.add-itinerary-modal__btn-container {
  width: 437px;
  height: 300px;
  overflow: auto;
  margin: 5px 0px;
}

.add-itinerary-modal__btn-container::-webkit-scrollbar {
  background: #f2f7fa;
  border-radius: 7px;
  width: 8px;
}

.add-itinerary-modal__btn-container::-webkit-scrollbar-track {
  background: #ddedf6;
  border-radius: 7px;
}

.add-itinerary-modal__btn-container::-webkit-scrollbar-thumb {
  background: #b7daed;
  border-radius: 7px;
}

.add-itinerary-modal__btn-container::-webkit-scrollbar-thumb:hover {
  background: #90c6e4;
  border-radius: 7px;
}

.add-itinerary-modal__btn {
  width: 100%;
  margin: 7px 0px;
  background-color: #f3f9fc;
  color: #363653;
}

.add-itinerary-modal__btn:hover {
  background-color: #e8f6fd;
  color: #363653;
}

.add-itinerary-modal__text{
    margin-bottom: 30px;
}

.add-itinerary-modal__form{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.add-itinerary-modal__form-input:first-child {
  width: 437px;
  height: 40px;
}

.import-quick-replies_label {
  font-size: 13px;
}
