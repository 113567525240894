.conversation {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    color: #363648;
    padding: 10px 15px;
    width: 23vw;
    margin: 5px;
}

.conversation-container {
    display: flex;
    width: -webkit-fill-available;
}

.conversation-container img {
    width: 3vw;
    height: auto;
    margin: 0px 10px 0px 0px;
}

.conversation-information {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    width: -webkit-fill-available;
}

.conversation-header {
    display: flex;
    justify-content: space-between;
    padding: 0px;
    margin: 0px;
}

.conversation-traveler-name {
    margin: 0px;
    color: #363648;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
}

.conversation-date-incoming-message {
    opacity: 0.6;
    color: #363648;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    margin: 0px;
}

.conversation-text {
    font-size: 13px;
}

.conversation-unread {
    margin: auto;
    margin-top: 5px;
    color: white;
    background-color: #1d2073;
    border: 1px solid #1d2073;
    border-radius: 15px;
    width: 20px;
    font-size: 12px;
}

.agent_icon {
    height: 56px;
    width: 70px;
    border-radius: 50%;
    text-align: center;
    margin-right: 10px;
    font-size: 36px;
    color: white;
}

a, a:hover{
    text-decoration: none !important;
}

.conversation-information:hover {
    text-decoration: underline;
}

.small_agent_icon {
    margin: 0px 2px;
    padding: 2px;
    border-radius: 50%;
    padding-left: 7px;
    padding-right: 7px;
    color: white;
}
