   .login__container {
    background-color:#F9FAFB ; 
    height: 100vh;
    padding: 5px; 
    margin: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} 
.login__container img{
    width: 22vw; 
}

.login__container input{
    border-radius: 5px;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.08);
    background: white; 
    margin-top: 20px;
    width: 22vw;
    padding: 10px 15px; 
    border: none;
}

.login__container button{
    color: white; 
    width: 80px;
    height: 32px;
    border-radius: 6px;
    background: #020360;
    border: none; 
    box-shadow: 0px 1px 3px rgba(0,0,0,0.08); 
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin-top: 20px;
    vertical-align: center;
}

.login__text{
    vertical-align: center;
    margin: 0px; 
}

.forgot_password_link {
    color: #020360;
    margin: 10px 0px 0px 0px;
    cursor: pointer;
}

.restore_password_btn {
    width: 125px !important;
}

.check_mail_label {
    font-size: 28px;
    color: #020360;
    padding-top: 20px;
}

.not_found_label {
    padding-top: 30px;
    font-size: 32px;
    color: #020360;
}

.system_message {
    color: #868691;
    font-size: 12px;
    margin: 10px 0px 0px 0px;
}
