.clear-button {
    height: 37px;
    width: 37px;
    margin-left: 15px;
    background-image: url("../../../images/deleteIcon.png");
    background-size: 12px;
    background-color: #ddedf6;
    background-repeat: no-repeat;
    background-position: center;
    border: solid 1px #c6c6c6;
    border-radius: 4px;
  }
  