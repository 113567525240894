/* style of tab that is selected */
a#inbox-tabs-tab-active.nav-item.nav-link.active, a#inbox-tabs-tab-complete.nav-item.nav-link.active{
  color: #363648;
  border-bottom: 4px solid #f38251; 
  font-size: 20px;
  font-weight: 900;
  line-height: 25px;
}

/* style of tab that is not selected */
a#inbox-tabs-tab-complete.nav-item.nav-link, a#inbox-tabs-tab-active.nav-item.nav-link{
  color: #868691;
  font-size: 20px;
  font-weight: 900;
  line-height: 25px;
}

/* styling of tab 'button' that is selected */
a#inbox-tabs-tab-active, a#inbox-tabs-tab-complete {
  border:none; 
  width: 15vw;
  padding: 15px 32px 15px 50px; 
  background-color:#F9FAFB;
  text-align: center;
  
}

  /* background right of the tabs */
  nav.nav.nav-tabs{
    border:none; 
    background-color:#F9FAFB;
  }

 