.private-layout {
  width: 1440px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.private-layout__nav-and-body {
  display: flex;
}

.private-layout__body {
  width: 100%;
  max-width: 1216px; 
}
