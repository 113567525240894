.add-location__form {
  padding: 15px 30px;
}

.add-location__days-and-btn {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 30px;
}
.add-location__days {
  display: flex;
  align-items: center;
}

.add-location__title {
  margin: 0px;
}

.add-location__start_day.form-group,
.add-location__end_day.form-group {
  margin: 0px;
}

.add-location__days-to {
  padding: 0px 20px 0px 20px;
}

.add-location__input-field {
  max-width: 4vw;
  min-width: 55px;
  margin-left: 10px;
}
