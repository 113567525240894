/* style of tab that is selected */
a#conversation-tabs-tab-open.nav-item.nav-link.active, a#conversation-tabs-tab-closed.nav-item.nav-link.active {
    color: #363648;
    border-bottom: 4px red solid;
    font-size: 20px;
    font-weight: 900;
    line-height: 25px;
    margin: 2px;
}

/* style of tabe that is not selected */
a#conversation-tabs-tab-open.nav-item.nav-link, a#conversation-tabs-tab-closed.nav-item.nav-link {
    color: #868691;
    font-size: 20px;
    font-weight: 900;
    line-height: 25px;
}

/* styling of tab 'button' that is selected */
a#conversation-tabs-tab-closed, a#conversation-tabs-tab-open {
    border: none;
    padding: 15px 32px 15px 50px;
    background-color: #F9FAFB;
    text-align: center;
}

/* background right of the tabs */
nav.nav.nav-tabs {
    border: none;
    background-color: #F9FAFB;
}

/* background closed conversations tab  */
.closed-conversations {
    background-image: url('https://res.cloudinary.com/dsuhcbmxk/image/upload/v1593443964/select-chat_ynlne9.png');
    z-index: 1;
    background-position: 70% 30%;
    background-repeat: no-repeat;
    background-size: 13vw auto;
    background-color: #F9FAFB;
    height: 100vh;
    padding: 5px;
    margin: 0px;
}

/* background open conversations tab  */
.open-conversations {
    background-image: url('https://res.cloudinary.com/dsuhcbmxk/image/upload/v1593443964/select-chat_ynlne9.png');
    z-index: 1;
    background-position: 70% 30%;
    background-repeat: no-repeat;
    background-size: 13vw auto;
    background-color: #F9FAFB;
    min-height: 100vh;
    padding: 5px;
    margin: 0px;
}

.open-conversations-row {
    background-color: #F9FAFB;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.conversation-search {
    border: none;
    height: 40px;
    border-radius: 17px !important;
    background: #ffffff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    background-image: url(https://res.cloudinary.com/dsuhcbmxk/image/upload/v1596034883/ComponentsSearchIcon_dsdhth.png);
    background-size: 25px;
    background-position-y: center;
    background-position-x: 3%;
    background-repeat: no-repeat;
    text-indent: 30px;
    font-size: 10px;
    outline: none;
    margin: 2% 1%;
}

textarea.conversation-search {
    min-height: 0px;
    height: 40px;
    padding-top: 10px;
    padding-left: 35px;
}

.conversation__search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
