.templates-search-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.templates-search-bar__select {
  width: 10vw;
  margin-left: 15px;
  color: #363648;
  text-indent: 5px;
  font-size: 12px;
}

