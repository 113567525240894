.task-conversation-container {
    display: flex;
    flex-direction: column;
}

.task-conversation-container {
    margin-top: 100px;
}

.open-conversation-task, .conversation-task-checkbox {
    display: block;
    align-items: center;
    margin: 5px;
    background-color: white;
    color: #363648;
    font-size: 14px;
    border-radius: 5px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}

.conversation-task-checkbox-input {
    margin: 10px 10px 10px 3px !important;
}

.task-conversations-header {
    color: #868691;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 1px;
    padding: 10px 0px 0px 15px;
    margin: 0px;
}

.tips-container {
    padding-top: 10px;
    padding-bottom: 10px;
    max-height: 700px;
    overflow-y: auto;
    overflow-x: hidden;
}

.tips-row {
    padding: 0px 10px;
}

.tips-line-sent {
    border-left: 2px solid #f38251;
    min-height: 50px;
    margin-left: 6px;
    height: 97%;
}

.tips-circle-sent {
    background: #f38251;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    position: inherit;
}

.tips-line-scheduled {
    border-left: 2px solid #e6e6e8;
    min-height: 50px;
    margin-left: 6px;
    height: 97%;
}


.tips-circle-scheduled {
    background: #e6e6e8;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    position: inherit;
}

.sent-tips {
    color: #4c4c5c;
    margin-top: 15px;
    margin-left: 5px;
}

.scheduled-tips {
    color: #363580;
    margin-top: 15px;
    margin-left: 5px;
}

.tips-date {
    color: #888893;
    font-weight: 600;
}

.tips-text {
    background-color: #e6e6e8;
    color: #363648;
    padding: 7px;
    border-radius: 7px;
    margin-bottom: 15px;
    word-break: break-word;
}

.scheduled-msg {
    background-color:#8599ad;
    display: flex;
    justify-content: space-between;
}

.tips__delete-scheduled-msg-btn{
    height: 25px;
    width: 25px;
    margin-left: 15px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAONJREFUOI2lkz0SgyAUhBe4QGozY+UVIJZJpx1H0JPpEVLSpZfG9BQ6ox5D04QZfxDNZDt+voV9bx4DAM6TLAyjS9eZBickRCqv1+jW9+ZN4ji5jyNeADBNyLVWpQ/mPMkIQWHv0/khISg4T7IzMAAwhoZ1nWmCIGoJgfyayCCI2mEwtQ+eJuRVpZ4MAIbB1D4TF2yjkqNXbDQXvDFwmczlKjJbX1rH8cEAQNcbv2rzg70Ie935u4jMB2utyqMWMx9s1z6TxSy4YF9ESvFYdOFomLRWpa3LxlmIVDoYp4RIpR26D58epYqfvTGQAAAAAElFTkSuQmCC);
    background-size: 12px;
    background-color: #DDEDF6;
    background-repeat: no-repeat;
    background-position: center;
    border: solid 1px #c6c6c6;
    border-radius: 4px;
}