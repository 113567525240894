.assignee__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.assignee__icon {
  width: 60px;
  padding: 10px;
}

.assignee__information {
  background-color: white;
  border: none;
  font-size: 14px;
  width: 100px;
}

.assignee__name {
  color: #363648;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 4px;
}

.assignee__type {
  color: #868691;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  margin: 4px;
}

.assignee__mark-uncomplete {
  border-radius: 6px;
  box-shadow: 0px 1px 3px rgba(0,0,0,0.08);
  border: none;
  color: white;
  font-size: 12px;
  padding: 4px 0px;
  background-color: #020360;
}

.assignee__mark-uncomplete:hover{
  opacity: 0.8;
}