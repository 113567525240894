.company__container{
  display:flex;  
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.company__icon{
  width: 33px; 
  height: 100%;
  margin: 8px;
  opacity: 0.8;
  background-image: url("../../images/company_icon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.company__name{
  color: #363648;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 4px;
}

.company__type {
  color: #868691;
  font-size: 12px;
  line-height: 16px;
  margin: 4px;
}