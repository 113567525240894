.massive-message-send {
    box-sizing: border-box;
    padding: 30px 30px 70px 30px;
    background-color: #f9fafb;
}

.massive-message-send__header{
    display:flex;
    justify-content: space-between;
  }
  
.massive-message-send__title {
    border-bottom: 3px solid #f38251;
    color: #363648;
    font-size: 20px;
    font-weight: 900;
    line-height: 28px;
}

.massive-message-send__form__input-field {
    width: 50vw;
}

.input-field-branch, .input-field-channel, .input-field-line, .input-field-language {
    width: 300px;
}

.massive-message-send__form {
    margin-top: 2rem;
}

.massive-message-send__btn {
    margin-top: 2.5rem;
}

.massive-message-send-modal__btn {
    display: flex;
    justify-content: center;
}

.bulk-messages-dates-row {
    margin: 0 !important;
}

.checkbox-input {
    margin-left: 5px;
}

.message-textarea {
    width: -webkit-fill-available;
}

.nav-link {
    color: #868691 !important;
}

.nav-link.active {
    color: #363648 !important;
}

.bulk-message-location > .auto-complete-location > .auto-complete-location__search-btn,
.bulk-message-location > .auto-complete-location > .auto-complete-location__clear-all-filters-btn {
    display: none;
}

.bulk-message-location > .auto-complete-location {
    display: block;
}

.bulk-message-location > .auto-complete-location >  div > .tips__search-location-input {
    border-right: 1px solid #cccccc;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    font-size: 1rem;
}

.users-table {
    height: 600px;
    overflow-x:auto;
}

.itineraries-block {
    line-height: 1;
}

.actions-btn {
    width: 65px;
    height: 25px;
    margin-left: 15px;
}

.massive-message-send-name {
    width: 100%;
}