.tip-details {
  width: auto;
  min-height: 466px;
  margin-top: 30px;
  padding: 1% 3% 7% 3%;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  background: white;
}

.tip-details__header {
  display: flex;
  justify-content: space-between;
}

.tip-details__name {
  border-bottom: 3px solid #f38251;
  color: #363648;
  font-size: 18px;
  font-weight: 900;
  line-height: 28px;
}

.delete_info {
  border-bottom: 3px solid #f38251;
  width: fit-content;
}

.tip-details__type__container {
  display: flex;
  align-items: center;
  margin-top: 6px;
}

.tip-details__type {
  margin: 3px;
  padding: 3px;
  color: #363648;
  font-size: 13px;
}

.tip-details__type__img {
  height: 3.5vh;
}

.tip-details__title {
  margin: 12px 0px 0px 0px;
  color: #868691;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
}

.tip-details__tag__container {
  display: flex;
}

.tip-details__tag {
  margin: 2px 8px 2px 0px;
  padding: 2px;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  background-color: #f9fafb;
  color: #363648;
  font-size: 11px;
}

.tip-details__text {
  color: #363648;
  font-size: 13px;
  line-height: 16px;
}

.tip-details__text--message {
  max-height: 66px;
  overflow: auto; 
}

.tip-details__text--message::-webkit-scrollbar {
  background: #f2f7fa;
  border-radius: 7px;
  width: 8px;
}

.tip-details__text--message::-webkit-scrollbar-track {
  background: #ddedf6;
  border-radius: 7px;
}

.tip-details__text--message::-webkit-scrollbar-thumb {
  background: #b7daed;
  border-radius: 7px;
}

.tip-details__text--message::-webkit-scrollbar-thumb:hover {
  background: #90c6e4;
  border-radius: 7px;
}

.tip-details__text--message .tip-details__text--notes {
  text-align: justify;
}

.tip-details__maps_url {
  overflow: hidden;
  color: #020360;
  font-size: 10px;
  text-decoration: underline;
}

.tip-details__btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.tip-details__close-btn {
  margin-left: 5px;
}
