/* styling background closed tab can be found in the 'conversations.css' file */

.closed-conversations-header {
    color: #868691;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 1px;
    padding: 10px 0px 0px 15px;
    margin: 0px;
}

.list-of-closed-conversations {
    max-height: 700px;
    overflow-y: auto;
    overflow-x: hidden;
}