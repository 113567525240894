.itinerary-timeline {
  position: relative;
}

.itinerary-timeline__no-template {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ddedf6;
  height: 100%;
}

.itinerary-timeline__add-btn {
  height: 50px;
  width: 50px;
  outline: none !important;
}

.itinerary-timeline__btn-container {
  position: absolute;
  display: flex;
  justify-content: center;
  right: 10px;
}

.itinerary-timeline__timeline {
  margin-top: 60px;
}

.itinerary-timeline__journey-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 1px;
}
