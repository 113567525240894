.individual-itinerary-container {
  min-height: 185px;
}

.individual-itinerary-container__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.individual-itinerary-container__details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.individual-itinerary-container__title {
  margin-right: 10px;
  color: #868691;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0px;
}

.individual-itinerary-container__data {
  margin-right: 10px;
  color: #363648;
  font-size: 14px;
  font-weight: 400;
}

.individual-itinerary-container__body {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
