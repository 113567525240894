.previous-trips__container {
  display: flex;
  align-items: center;
  margin: 20px 10px;
  padding: 10px 20px;
  border-radius: 7px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}

.previous-trips__body.modal-body {
  padding: 0px 20px;
}

.previous-trips__link:hover {
  text-decoration: none;
}

.previous-trips__link:hover .previous-trips__container {
  background-color: #ddedf6;
}

.previous-trips__icon {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  background-image: url(../../../../images/airballoon.png);
  background-size: 40px;
}

.previous-trips__name {
  color: #868691;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0px;
}

.previous-trips__start-end-date {
  color: #363648;
  font-size: 14px;
}
