.conversation-details {
    display: flex;
    flex-direction: column;
    margin: 5px;
    width: 100%;
    background-color: white;
    color: #363648;
    padding: 10px 15px;
    border-radius: 5px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}

.conversation-details-name {
    font-size: 12px;
    font-weight: bold;
    margin: 0px;
}

.conversation-details-text {
    font-size: 12px;
    margin: 0px;
}

.open-conversations-row {
    margin-left: 0px !important;
    margin-right: 0px !important;
}