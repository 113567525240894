.edit-template-form__name {
  margin-top: 25px;
  color: #868691;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
}

.edit-template-form__name:first-child {
  margin-top: 0px;
}

.edit-template-form__form-container {
  padding: 3% 3% 0.1% 3%;
  margin: 2% 0% 4% 0%;
  border-radius: 7px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  background: #ffffff;
}

.edit-template-form__header {
  display: flex;
  margin: 2px 0px;
  color: #868691;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}
 
.edit-template-form__input-field.form-control,
.edit-template-form__alias,
.edit-template-form__input-select {
  margin-bottom: 10px;
  color: #808080;
  font-size: 12px;
}

.edit-template-form__alias {
  padding-left: 0px;
}