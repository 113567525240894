.task-template__container, .task-template-day__container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .task__empty-container {
      width: 100px;
      height: 100px;
  }

  .task-template__title, .task-template-day__title {
    color: #363648;
    font-size: 14px;
    line-height: 70px;
    margin: 3px;
  }
