.data-block {
  width: 100%;
}

.data-block__header {
  margin: 20px 0px 10px 0px;
  color: #868691;
  font-size: 12px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 1px;
}

.data-block__content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 7px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
}

.data-block__title {
  color: #868691;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0px;
}

.data-block__text {
  margin-bottom: 10px;
  color: #363648;
  font-size: 13px;
  font-weight: 400;
}

.data-block__tag-container {
  display: flex;
  flex-wrap: wrap; 
}

.data-block__tag {
  margin: 1% 3% 0% 0%;
  padding: 3px 7px;
  border-radius: 7px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  background: #ddedf6;
  color: #363648;
  font-size: 10px;
  line-height: 14px;
}
