.itinerary__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.itinerary__icon {
  background-image: url("../../images/airballoon.png");
  background-repeat: no-repeat;
  background-position: center;
  margin: 8px;
  width: 35px;
  height: 100%;
  background-size: contain;
}

.itinerary__trip-name {
  color: #363648;
  font-size: 14px;
  line-height: 20px;
  margin: 3px;
}

.itinerary__trip-dates {
  color: #868691;
  font-size: 12px;
  line-height: 16px;
  margin: 3px;
}

.itinerary__progressbar {
  width: 130px;
  height: 5px;
  background: white;
  max-height: 4px;
  margin: 9px 3px 3px 3px;
}

.bg-itinerary__progressbar {
  background-color: #f38251 !important;
  opacity: 0.6;
  max-height: 5px;
}

.progress {
  max-height: 5px !important;
}
