.itinerary-row:nth-child(even) {
  background: #f3f9fd;
}

.itinerary-row:nth-child(even):hover {
  background: #ddedf6;
}

.itinerary-row:nth-child(even) a:hover {
  text-decoration: none;
}

.itinerary-row:nth-child(odd):hover {
  background: #dae5eb;
}

.itinerary-row:nth-child(odd) a:hover {
  text-decoration: none;
}

.itinerary-row__body {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 10% 10% 17% 17% 10% 10% 10% 10%;
  grid-auto-rows: minmax(15px, auto);
  grid-gap: 5px;
  margin: 0 auto;
  align-items: center;
  padding: 5px 10px;
}

.itenerary-row__booking-number,
.itinerary-row__assigned-to,
.itinerary-row__name,
.itinerary-row__start-date,
.itinerary-row__end-date {
  color: #363648;
  font-size: 14px;
  line-height: 20px;
}

.itinerary-row__assigned-to {
  height: 28px;
  width: 28px;
  line-height: 28px;
  border-radius: 50%;
  margin: 2px;
  text-align: center;
}

.itinerary-row__customer {
  display: flex;
  align-items: center;
}

.itinerary-row__trip {
  color: #363648;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.itinerary-row__icon {
  width: 22px;
  height: 22px;
  margin: 12px 12px 12px 0px;
  opacity: 0.4;
  background-image: url("../../../images/IconAvatar-Placeholder.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.itinerary-row__status {
  width: 75px;
  height: 20px;
  margin: 0px;
  border-radius: 7px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  background-color: lightblue;
  font-size: 12px;
  text-align: center;
  margin: 3px;
}

.itinerary-row__progress {
  color: #363648;
}

.itinerary-row__progressbar {
  width: 130px;
  height: 5px;
  max-height: 4px;
  margin: 9px 3px 3px 3px;
  background: white;
}

.bg-itinerary-row__progressbar {
  background-color: #f38251 !important;
  opacity: 0.6;
  max-height: 5px;
}

.progress {
  max-height: 5px !important;
}

.itinerary-row__travel-party,
.itinerary-row__days-remaining {
  color: #868691;
  font-size: 10px;
  line-height: 14px;
}

.itinerary-row__assigned-to-container {
  display: flex;
}

.agent_task-icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  text-align: center;
  margin-right: 10px;
  font-size: 20px;
  color: white;
}
