.add-template__form {
  padding: 2%;
}

.add-template__title {
  margin-top: 15px;
  color: #868691;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
}

.add-template__title:first-child {
  margin-top: 0px;
}

.add-template__btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.add-template__error-message {
  color: rgb(206, 0, 0);
  font-weight: 600;
}
