.open-conversations-header {
    color: #868691;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 1px;
    padding: 10px 0px 0px 15px;
    margin: 0px;
}

.conversation-close, .conversation-delete {
    position: absolute;
    right: 2px;
    top: -70px;
}

.conversation-open {
    position: absolute;
    right: 175px;
    top: -70px;
}

.closed-conversation-block {
    max-width: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.conversation-close button, .conversation-open button, .conversation-delete button {
    color: white;
    background-color: #1d2073;
    border: 1px solid #1d2073;
    border-radius: 5px;
    padding: 5px 15px;
    margin-top: 3px;
}

.conversation-delete button, .conversation-delete button:hover, .conversation-delete button:focus {
    background-color: #F3815A !important;
    border: 1px solid #F3815A !important;
}

.delete-button {
    background-color: #F3815A !important;
    border: 1px solid #F3815A !important;
}

.my-conversation-block, .other-conversation-block {
    max-height: 700px;
    overflow-y: auto;
    overflow-x: hidden;
}

.more_conversations {
    color: #868691;
    cursor: pointer;
    text-align: center;
    margin-top: 10px;
}

.more_conversations p {
    border: 1px solid #868691;
    width: 208px;
    border-radius: 6px;
    margin: auto;
}

.conversation__multi-close-on {
    border: 0;
    margin: 0;
    background-color: transparent;
    height: 5vh;
    width: 5vw;
}

#search_input_open {
    width: 25vw;
} 

.conversation__card-multi-close {
    display: flex;
    align-items: center;
}

.conversation__multi-close-handle-btn {
    border: 0;
    margin: 0;
    background-color: transparent;
    height: 3.5vh;
    width: 3.4vw;
    padding: 2px;
}

.conversation-dropdown__select {
    width: 165px;
    margin-left: 8px;
    color: #363648;
    text-indent: 5px;
    font-size: 12px;
}

.conversation__select {
    display: flex;
    justify-content: flex-end;
}
