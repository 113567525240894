.individual-itinerary {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-auto-rows: minmax(15px, auto);
  grid-column-gap: 40px;
  grid-row-gap: 0px;
  width: 100%;
  margin: 0 auto;
  padding: 2vw;
  background-color: #f9fafb;
}

.individual-itinerary__header {
  grid-column: 1/9;
}

.individual-itinerary__itinerary-container {
  grid-column: 1/5;
}

.individual-itinerary__travellers-container {
  grid-column: 5/9;
}

.individual-itinerary__timeline {
  grid-column: 1/7;
  grid-row: 3/4;
  overflow: auto; 
}

.individual-itinerary-timeline__journey-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 1px;
  margin-bottom: 14px;
}

.individual-itinerary__task-list {
  grid-column: 7/9;
  grid-row: 3/4;
  height: auto;
}

.individual-itinerary__activity-log {
  grid-column: 7/9;
}

.individual-itinerary__traveller-summary {
  grid-column: 7/9;
}

.individual-itinerary__itinerary-analysis {
  grid-column: 1/7;
  grid-row: 4/5;
}

.individual-itinerary__template-day-message {
  display: grid;
  grid-template-columns: 73% auto;
  grid-auto-rows: minmax(15px, auto);
  width: 100%;
  margin: 0 auto;
  padding: 2vw;
  background-color: #f9fafb;
}
