.activity-log {
  padding-right: 3px;
  color: #868691;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0px;
}

.activity-log__date-time,
.activity-log__name-type {
  display: flex;
}

.activity-log--name,
.activity-log--type {
  padding: 2px 3px 15px 0px;
  color: #363648;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
}

.activity-log--name {
  padding-right: 3px;
  font-weight: 900;
}
