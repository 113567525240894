.circle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: #ddedf6;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  padding: 0;
  border: none;
  font-size: 20px;
  font-weight: 900;
  color: #020360;
}

.circle-button:hover {
  background-color: #c0d9e7;
  text-decoration: none;
}

.circle-button:disabled {
  background: #bfc0c6;
  color: #ffffff;
}

.circle-button--prev,
.circle-button--next {
  display: block;
  position: relative;
}

.circle-button--prev:before,
.circle-button--next:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 15px;
  height: 15px;
  box-shadow: -3px 3px 0 #020360;
}

.circle-button--prev:before {
  transform: rotate(45deg);
  left: 12px; 
}

.circle-button--next:before {
  transform: rotate(-135deg);
  right: 12px; 
}

.circle-button--add {
  display: block;
  position: relative;
}

.circle-button--add:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 3px;
  height: 23px;
  margin: auto;
  border-radius:35%;
  background-color: #020360;
}

.circle-button--add:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 23px;
  height: 3px;
  margin: auto;
  border-radius:35%;
  background-color: #020360;
}
