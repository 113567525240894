/*Assignee*/
.assign_block {
  width: -webkit-fill-available;
}

.assignee__icon {
  padding: 6px 0px !important;
}

.assignee_avatar {
  width: 30px;
  height: 100%;
  border-radius: 50%;
  margin-right: 10px;
}

.assignee__text {
  line-height: 3;
  font-size: 14px;
  font-weight: 400;
}

/*NoAssignee*/
.open-conversation-task {
  padding: 5px 10px;
  min-height: 40px;
  /*max-height: 700px;*/
  /*overflow-y: auto;*/
  /*overflow-x: hidden;*/
}

.company_name {
  font-size: 15px;
  padding-left: 7px;
  padding-top: 4px;
  font-weight: 400;
  padding-bottom: 4px;
  margin: 0px;
}

.no-assignee__icon {
  margin: 0px !important;
}

.no-assignee {
  background: transparent !important;
  font-size: 14px !important;
  color: #020360 !important;
}

.itinerary-block {
  max-height: 700px;
  overflow-y: auto;
  overflow-x: hidden;
}

.default-tab-info__company-name-agent {
  display: flex;
}

.open-conversation-task--add-itinerary {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.default-tab-info__add-itinerary-btn {
  margin: 5px 0px;
}

.conversation-notes{
  width: -webkit-fill-available;
  border: 1px solid hsl(0, 0%, 80%);
  padding: 5px;
  margin-bottom: 5px;
}

.conversation-notes-block{
  text-align: right !important;
}

.conversation-notes-block > input {
  padding: 1px 5px;
}

.default-tab-info__note-submit-btn {
  width: 20%;
}
