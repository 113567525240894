.task-conversation-container {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
}

.open-conversation-task, .conversation-task-checkbox {
    display: flex;
    align-items: center;
    margin: 5px;
    background-color: white;
    color: #363648;
    font-size: 12px;
    border-radius: 5px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}

.conversation-task-checkbox-input {
    margin: 10px 10px 10px 3px !important;
}

.task-conversations-header {
    color: #868691;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 1px;
    padding: 10px 0px 0px 15px;
    margin: 0px;
}

.conversation-task-label {
    display: flex;
    align-items: center;
    margin: 5px 0px;
    color: #363648;
    font-size: 14px;
}

.no-assignee, .no-assignee:active {
    line-height: 3;
    outline:none !important;
}

.conversation-task-block {
    width: -webkit-fill-available;
    display: contents;
}