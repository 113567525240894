.template-header {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(20px, auto);
  grid-gap: 20px;
  width: 100%;
  margin: 0 auto;
}

.template-header__left-column {
  grid-column: 1/4;
  display: flex;
  justify-content: space-between;
}

.template-header__btn-and-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.template-header__title-container {
  margin-left: 20px;
}

.template-header__title {
  color: #363648;
  font-size: 20px;
  font-weight: 900;
  line-height: 20px;
}

.template-header__add-location {
  margin-right: 0px;
}

.template-header__right-column {
  grid-column: 4/5;
}

.template-header__edit-btn-container {
  display: flex;
  justify-content: flex-end;
}

.template-header__edit-btns-container {
  display: flex;
  justify-content: space-between;
}

.template-header__empty-container {
  height: 54px;
}

.template-header__dropdown {
  background: #F3815A;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 32px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08) !important;
  color: white !important;
  font-size: 14px !important;
  text-align: center ;
}
.template-header__dropdown:hover {
  background: #d45e36;
}
