.text-input__input-field {
  width: 100%;
  padding: 5px 15px;
  margin: 20px 0px;
  border-radius: 7px;
  border: solid 1px #ced4da;
}

.text-input__btn-container {
  display: flex;
  justify-content: flex-end;
}

