.Imported, .imported {
  background-color: #8599ad;
  color: white;
}

.Opt-in, .opt-in {
    background-color: #80c1e4;
    color: white;
  }
  
  .Mismatch, .mismatch {
    background-color: #f38251;
    color: white;
  }

  .Unvalidated, .unvalidated {
    background-color: #ee4e36;
    color: white;
  }
  
  .Template, .template {
    background-color: #f38251;
    color: white;
  }
  
  .Analyse, .analyse {
    background-color: #ffb300;
    color: white;
  }
  
  .Cancelled, .cancelled {
    background-color: #f193a3;
    color: white;
  }
  
  .Opt-out, .opt-out {
    background-color: #363648;
    color: white;
  }

  .Analysed, .analysed {
    background-color: #0fa484;;
    color: white;
  }

  .Current, .current{
    background-color: #3f408a;;
    color: white;
  }




 
  