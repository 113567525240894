.import-templates {
    box-sizing: border-box;
    padding: 30px 30px 70px 30px;
    background-color: #f9fafb;
}

.import-templates__header{
    display:flex;
    justify-content: space-between;
  }
  
  .import-templates__title {
    border-bottom: 3px solid #f38251;
    color: #363648;
    font-size: 20px;
    font-weight: 900;
    line-height: 28px;
  }

.import-templates__form__input-field {
    width: 50vw;
}

.input-field-branch, .input-field-channel, .input-field-line {
    width: 300px;
}

.import-templates__form {
    margin-top: 2rem;
}

.import-templates__btn {
    margin-top: 2.5rem;
}

.import-templates-modal__btn {
    display: flex;
    justify-content: center;
  }
  