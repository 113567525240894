.travellers-container{
    height: 260px;
    display: flex;
    justify-content: space-between;
  }

  .traveller-container__traveller-cards {
    max-height:165px;
    overflow: auto;
    overflow-x: hidden; 
  }

  .traveller-container__traveller-cards::-webkit-scrollbar {
    width: 7px;
    background: #f2f7fa;
    border-radius: 7px;
  }
  
  .traveller-container__traveller-cards::-webkit-scrollbar-track {
    background: #ddedf6;
    border-radius: 7px;
  }
  
  .traveller-container__traveller-cards::-webkit-scrollbar-thumb {
    background: #b7daed;
    border-radius: 7px;
  }
  
  .traveller-container__traveller-cards::-webkit-scrollbar-thumb:hover {
    background: #90c6e4;
    border-radius: 7px;
  }