.nav-bar {
  display: flex;
  flex-direction: column;
  width: 224px;
}

.nav-bar__link {
  padding: 10px 0px 10px 0px;
  opacity: 0.8; 
  border-left: 4px solid white;
  color: #363648;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.nav-bar__link:hover {
  opacity: 1;
  border-left: 4px solid #f3815a;
  color: #363648;
  font-weight: bold;
  text-decoration: none;
}

.nav-bar__link.active {
  opacity: 1;
  border-left: 4px solid #f3815a;
  font-weight: bold;
}

.sub-nav-import-templates {
  padding-left: 15px;
}

.nav-templates {
  display: flex;
}

.sub-nav-conversation {
  padding-left: 15px;
}

.nav-conversation {
  display: flex;
}
