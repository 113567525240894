.tips {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 56% auto;
  grid-auto-rows: auto auto;
  grid-gap: 20px;
  grid-template-areas:
    "tips__header--left-column tips__header--right-column"
    "tips__body tips__detail-and-googlemap-container";
  margin: 0 auto;
  padding: 30px 30px 70px 30px;
  background-color: #f9fafb;
}

.tips__header--left-column {
  grid-area: tips__header--left-column;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tips__title {
  color: #363648;
  border-bottom: 3px solid #f38251;
  font-size: 20px;
  font-weight: 900;
  line-height: 28px;
}

.tips__header--right-column {
  grid-area: tips__header--right-column;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  display: grid;
  grid-template-columns: 28% 28% 38%;
  height: 76px;
  overflow: visible;
  z-index: 999;
}

.tips__search-type,
.tips__search-tags,
.tips__search-location-input,
.tips__search-radius {
  width: 100%;
  color: #363648;
  text-indent: 1px;
  font-size: 10px;
}

.tips__search-radius {
  margin-top: 8px;
}

.tips__search-location-input {
  height: 38px;
  width: 100%;
  border: 1px solid #cccccc;
  border-right: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  text-indent: 10px;
}

.tips__search-suggestions {
  width: 10vw;
  padding: 3px 3px 3px 3px;
  margin: 0;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  background: white;
  cursor: pointer;
  list-style-type: none;
  font-size: 13px;
}

.tips__search-suggestions:hover {
  background: #f3f9fd;
}

.tips__body {
  grid-area: tips__body;
}

.tips__table {
  width: 100%;
}

.tips__table-header {
  display: grid;
  grid-template-columns: 5% 23% 34% 25%;
  grid-auto-rows: minmax(20px, auto);
  grid-gap: 15px;
  box-sizing: border-box;
  padding: 6px 10px;
  margin: 0 auto;
  color: #868691;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

.tips__table-body {
  height: 100%;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}

.tips__detail-and-googlemap-container {
  grid-area: tips__detail-and-googlemap-container;
  min-height: 60vh;
}

.tips-paginate {
  position: fixed;
  bottom: 25px;
  left: 50%;
}

.tips-search-bar__input{
  border-top-right-radius: 4px;
  border-right: solid 1px #c6c6c6;
  border-bottom-right-radius: 4px;
  margin-top: 8px;
  margin-left: 0px;
  width: 200px;
}

.tips__paginate {
  margin-top: 1%;
}
