.rectangle-btn{
  display: inline-flex;
  align-items: center; 
  justify-content: center;
  width: 120px;
  height: 32px;
  background: #020360;
  border-radius: 6px;
  border: none; 
  outline: none!important; 
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  color: white;
    font-size: 14px;
    text-align: center;
  }

  .rectangle-btn:hover {
    background: #00011a;
    color:white; 
    text-decoration: none;
  }

  .rectangle-btn:disabled{
    background:#BFC0C6;
    }

    .rectangle-btn--orange{
      background: #F3815A;
    }

    .rectangle-btn--orange:hover{
      background: #d45e36;
    }

    .rectangle-btn--small {
      width: 80px; 
    }

    .rectangle-btn--large {
      width: 140px; 
      height: 50px;
    }

    .rectangle-btn--small-orange{
      background: #F3815A;
      width: 80px; 
    }

    .rectangle-btn--small-orange:hover{
      background: #d45e36;
    }

