.template-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(20px, auto);
  grid-gap: 20px;
  width: 100%;
  padding: 2vw;
  margin: 0 auto;
  background-color: #f9fafb;
}

.template-header {
  grid-column: 1/5;
}

.template-timeline {
  grid-column: 1/4;
  overflow: auto; 
}

.template-details {
  grid-column: 4/5;
}
