.templates {
  box-sizing: border-box;
  position: relative;
  width: auto;
  min-height: 92vh;
  padding: 2vw;
  background-color: #f9fafb;
}

.templates__header{
  display:flex;
  justify-content: space-between;
}

.templates__title {
  border-bottom: 3px solid #f38251;
  color: #363648;
  font-size: 20px;
  font-weight: 900;
  line-height: 28px;
}

.templates__table {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 168px;
  grid-gap: 5px 20px;
  max-width: 1216px;
  margin: 0 auto;
  margin-top: 1%;
}

.templates__paginate{
  position: fixed;
  bottom: 25px;
  left: 50%;
}

.templates__new-template-btn {
  position: absolute;
  bottom: 25px;
  right: 25px;
  z-index: 99;
  height: 50px;
  width: 50px;
}
