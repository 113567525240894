.template-item__key {
  margin: 25px 0px 10px 0px;
  color: #363648;
  font-size: 14px;
  line-height: 16px;
}

.template-item__tip {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.template-item__value {
  width: 80%;
  padding: 8px 12px;
  border-radius: 7px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  background-color: #fdffff;
  color: #363648;
  font-size: 14px;
}

