.itineraries {
  box-sizing: border-box;
  position: relative;
  width: auto;
  min-height: 92vh;
  padding: 2vw;
  background-color: #f9fafb;
}

.itineraries__table-header {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 10% 10% 17% 17% 10% 10% 10% 10%;
  grid-auto-rows: minmax(20px, auto);
  grid-gap: 5px;
  margin: 0 auto;
  padding: 5px;
  color: #868691;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

.itineraries__filter-date {
  margin-bottom: 16px;
  padding: 0px;
  outline: none !important;
  background: #f9fafb;
  border: none;
  color: #868691;
  text-align: left;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

.itineraries__arrow {
  display: inline-block;
  margin-left: 5px;
  padding: 3px;
  border: solid #868691;
  border-width: 0 3px 3px 0;
}

.itineraries__down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.itineraries__up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.itineraries__table-body {
  height: 100%;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}

.itineraries__new-btn {
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 30px;
  right: 70px;
  z-index: 999;
}
