.dashboard {
    box-sizing: border-box;
    padding: 30px 30px 70px 30px;
    background-color: #f9fafb;
}

.title {
    font-size: 20px;
}

.last-update {
    padding: 10px 0px;
}

.last-update img {
    margin-right: 5px;
}

.dashboard-select {
    padding: 10px 0px;
}

.error-block {
    margin-left: 20px;
}

.small-error-icon {
    height: 16px;
    width: 16px;
    margin-left: 5px;
    margin-right: 5px;
}

.error-block {
    margin-left: 20px;
}

.error-type {
    cursor: pointer;
}

.date-inputs {
    width: 150px;
    display: flex;
}

.upload_errors_btn {
    margin: 0px 0px 0px 5px !important;
}

.error-block-mt {
    margin-top: 55px;
}

.error-title {
    margin-top: 5px;
    margin-bottom: 5px;
}