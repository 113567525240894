.template-details__name {
  color: #868691;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
}

.template-details__container {
  padding: 3%;
  margin: 2% 0% 4% 0%;
  background: #ffffff;
  border-radius: 7px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}

.template-details__header {
  color: #868691;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.template-details__information {
  margin-bottom: 5%;
  color: #363648;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
}

.template-details__tag-container {
  display: flex;
  margin-bottom: 1%;
  flex-wrap: wrap;
}

.template-details__tag {
  margin: 1% 3% 0% 0%;
  padding: 3px 7px;
  border-radius: 7px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  background: #e6e6e8;
  color: #363648;
  font-size: 10px;
  line-height: 14px;
}

.template-details__created-by {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.template-details__information--name,
.template-details__information--created-at,
.template-details__information--company {
  margin-bottom: 0%;
}
