.itinerary-container {
  min-height: 260px;
}

.itinerary-container__details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.itinerary-container__details-block {
  flex-direction: row;
  justify-content: space-between;
}

.itinerary-container__title {
  color: #868691;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0px;
}

.itinerary-container__data {
  color: #363648;
  font-size: 14px;
  font-weight: 400;
}

.itinerary-container__input-field {
  width: 90px;
}

#departure_date.form-control,
#return_date.form-control {
  font-size: 12px;
  color: #8688a3;
  height: 20px;
}

.itinerary-container__accommodation-container {
  display: flex;
  overflow: auto;
  width: 100%;
}

.itinerary-container__accommodation-container::-webkit-scrollbar {
  height: 6px;
  background: #f2f7fa;
  border-radius: 7px;
}

.itinerary-container__accommodation-container::-webkit-scrollbar-track {
  border-radius: 5px;
  background: #ddedf6;
}

.itinerary-container__accommodation-container::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #b7daed;
}

.itinerary-container__accommodation-container::-webkit-scrollbar-thumb:hover {
  border-radius: 5px;
  background: #90c6e4;
}

.itinerary-container__title--accommodation {
  margin-top: 20px;
}

.itinerary-container__accommodation {
  min-width: 90px;
  margin: 0px 15px 10px 0px;
  color: #363648;
  font-size: 12px;
}

.itinerary-container__travel-party {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
}
