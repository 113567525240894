.add-itinerary__form {
  padding: 2%;
}

.add-itinerary__title {
  margin: 1px 0px 0px 0px;
  color: #212529;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
}

.add-itinerary__btn {
  display: flex;
  justify-content: flex-end;
}
