.tip-form {
  display:flex; 
  justify-content: center;
  flex-direction: column;
  padding: 1%;
}

.tip-form__title {
  margin: 10px 0px 0px 8px;
  color: #868691;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
}

.tip-form__draft {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.tip-form__title--draft{
  margin: 0px 0px 0px 8px; 
}

.tip-form__btns {
  display: flex;
  justify-content: center;
}

.tip-form__delete-btn{
  margin-right: 5px;
  background: #bb1010!important;
}

.tip-form__delete-btn:hover{
  background: #800909!important;
} 

.tip-form__delete-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
}

.tip-form__delete-text{
  margin-bottom: 15px;
}

.tip-form__delete-btns{
  display: flex; 
  justify-content: space-around; 
}