.paginate-btn{
display:flex;
align-items: center;
justify-content: center; 
}

.paginate-btn--page{
  width:30px;
  height:30px;
  background-color: #DDEDF6;
  background-size: 30px;
  margin: 5px;
  border: none; 
  border-radius: 50%;
  font-size: 14px;
}

.paginate-btn__dots{
  margin: 5px 10px;
}

.paginate-btn--previous, .paginate-btn--next{
    width:30px;
    height:30px;
    background: url(../../../images/icon-arrow.png) center;
    background-size: 30px;
    margin: 5px;
    border: none; 
}

.paginate-btn--next{
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(9180deg);
    transform: rotate(180deg);
}

.paginate-btn--previous:disabled,
.paginate-btn--next:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.paginate-btn--previous:focus,
.paginate-btn--next:focus {
    outline: none;
  }