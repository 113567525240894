.no-assignee__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.no-assignee__icon {
  width: 30px;
  height: 100%; 
  margin: 8px; 
  opacity: 0.2;
  background-image: url('../../../../../images/IconAvatar-Placeholder.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.no-assignee{
  background-color:white;
  border: none; 
  font-size: 14px; 
  width: 100px;
  padding: 0px;
  display: inline-grid;
  flex-direction:column;
  justify-content: flex-start;
}

.no-assignee__mark-as-complete {
  border-radius: 6px;
  background-color: #0fa484;
  border: none;
  color: white;
  size: 12px;
  padding: 5px;
  margin: 15px 0px;
}


.no-assignee u {
  color: #868691;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  margin: 4px;
}

.no-assignee__information p {
  font-size: 14px;
  margin: 4px;
}