.itinerary-analysis__header {
  display: flex;
  justify-content: space-between;
}

.itinerary-analysis__title {
  color: #868691;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0px;
}

.itinerary-analysis__data {
  margin-bottom: 15px;
  color: #363648;
  font-size: 14px;
  font-weight: 400;
}

.itinerary-analysis__keywords {
  margin-bottom: 15px;
}

.itinerary-analysis__tag {
  margin: 5px 10px 5px 0px;
  padding: 3px 7px;
  border-radius: 7px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  background: #ddedf6;
  color: #363648;
  font-size: 12px;
  line-height: 14px;
}
