.conversation-details-open {
  max-height: 53vh;
  display: flex;
  flex-direction: column;
  width: 25vw;
  color: #363648;
  padding: 10px 15px;
  min-height: 550px;
  background-color: #f9fafb;
  overflow-x: hidden;
}

.conversation-date p {
  margin: auto;
  margin-top: 15px;
  max-width: 90px;
  top: 12px;
  left: 40%;
  background-color: #ddedf6;
  color: #1d2073;
  padding: 3px 10px;
  border-radius: 15px;
  font-size: 14px;
}

.conversation-date hr {
  border: 1px solid #ddedf6;
  margin-top: -13px;
}

.conversation-author-message {
  background-color: #ced4da;
  padding: 10px;
  border-radius: 15px 15px 0px 15px;
  margin-top: 10px;
}

.conversation-traveler-message {
  background-color: #90c6e4;
  padding: 10px;
  border-radius: 15px 15px 15px 0px;
  margin-top: 10px;
}

.conversation-dots {
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  color: #6c757d;
}

.conversation-details-text-open {
  font-size: 15px;
  margin: 0px;
  white-space: pre-wrap;
}



.conversation-details-image {
  max-width: 100%;
}

.conversation-dots > div > button,
.conversation-dots > div > button:hover,
.conversation-dots > div > button:active,
.conversation-dots > div > button.dropdown-toggle,
.conversation-dots > div > button:not(:disabled):not(.disabled).active,
.dropdown-toggle {
  margin-left: 0px;
  font-size: 24px !important;
  background-color: transparent !important;
  border-color: transparent !important;
  color: #6c757d !important;
  border: none !important;
  box-shadow: none !important;
  padding: 7px !important;
}

.conversation-dots > div > button:after {
  content: "*";
  position: absolute;
  top: 0;
  left: 9999px;
}

.dropdown-item {
  font-size: 12px !important;
  padding-left: 17px !important;
}

.circle {
  display: inline-block;
  width: 8px;
  height: 8px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  margin-right: 5px;
}

.dropdown-toggle {
  font-size: 12px !important;
  margin-left: 10px;
  color: #212529 !important;
}

.conversation-input-container {
  background-image: url(../../../images/IconAttachment.png);
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  background: white;
  padding: 10px 15px;
}

.conversation-input-container label {
  width: max-content !important;
}

.conversation-input-container__label-file-upload {
  background-color: gray;
  color: white;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 1rem;
}

#file-chosen{
  margin-left: 0.3rem;
  font-family: sans-serif;
  padding: 0.3rem;
  margin-top: 1rem;
  font-style: italic;
  width: 105px;
}

.conversation-input-field-btn {
  color: white;
  width: 80px;
  height: 32px;
  border-radius: 6px;
  background: #020360;
  border: none;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  margin-top: auto;
  margin-left: 5px;
}

.conversation-input-field-btn--disabled {
  background: #c1c1c1;
}

.conversation-message__message{
    font-size: 16px;
}

.conversation-input-container textarea {
  /*resize: none;*/
  font-size: 15px;
  width: inherit;
  border: none;
  outline: none;
}

.conversation-input-container textarea {
  width: -webkit-fill-available;
}

.templates {
  position: relative;
  height: 200px;
  overflow: auto;
  display: block;
}

.templates > table {
  font-size: 13px;
}

th,
td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

tr {
  cursor: pointer;
}

.emoji-picker-react {
  width: auto !important;
  height: 200px !important;
}

.emoji-search {
  font-size: 12px;
}

.write-message-menu {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.write-message-menu span {
  margin-right: 10px;
}

.emoji-icon {
  margin-bottom: 5px !important;
  cursor: pointer;
}

.message-tag {
  margin-bottom: 10px;
  word-wrap: break-word;
}

.reaction_block {
  display: flex;
  justify-content: flex-start;
  background-color: #90c6e4;
  padding: 10px;
  border-radius: 15px 15px 15px 0;
  margin-top: 10px;
  width: fit-content;
  margin: -25px 0 0 -10px;
}

.three-dots-menu > button {
  padding: 0px !important;
  padding-left: 14px !important;
}

.traveller_reaction {
  background-color: #ced4da;
}

.message-tag > span {
  margin-left: 3px;
  margin-right: 3px;
  padding: 5px;
  border-radius: 6px;
  line-height: 2.5;
  white-space: nowrap;
  font-weight: 600;
  font-size: 12px;
  color: white;
}

.manager_menu > div {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}

.disabled-btn {
  background-color: #6e6e6e;
}

.traveler_name {
  color: #363648;
  font-size: 20px;
  font-weight: 900;
  border-bottom: 3px solid red;
  width: fit-content;
  margin: auto;
  margin-top: 12px;
  margin-bottom: 20px;
}

.traveler_name p {
  margin: 0px;
}

.more_messages {
  color: #868691;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
}

.more_messages p {
  border: 1px solid #868691;
  width: 208px;
  border-radius: 6px;
  margin: auto;
}

textarea {
  min-height: 150px;
}

.message-time {
  margin-top: 5px;
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.conversation-templates {
  box-sizing: border-box;
  position: relative;
  width: auto;
  min-height: 70vh;
  padding: 2vw;
  background-color: #f9fafb;
}

.reply_message_text {
  white-space: pre-wrap;
}

.quoted-message, .conversation-traveler-message > .quoted-message > .quoted-message {
    border-left: 3px solid gray;
    background-color: white;
    border-radius: 6px;
    line-height: 1;
    margin-bottom: 10px;
    padding: 5px;
}

.quoted-message p, .conversation-traveler-message > .quoted-message > .quoted-message p {
    margin-bottom: 0px;
}

.quoted-message p:first-child, .conversation-traveler-message > .quoted-message > .quoted-message p:first-child {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px !important;
}

.quoted-message p:last-child, .conversation-traveler-message > .quoted-message > .quoted-message p:last-child {
    padding-bottom: 10px;
    font-size: 12px !important;
}

.conversation-author-message > div > div > span > div > div > div > div > div > div {
    margin-left: 0px !important;
}

.conversation-author-message > div > div > span > div > div > div > div > div > div > div:first-child {
    line-height: 1 !important;
    font-size: 18px !important;
}

.conversation-author-message > div > div > span > div > div > div > div > div > div > div:last-child {
    font-size: 16px !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.conversation-author-message > p {
  margin-bottom: 0px;
}

.message-pending {
  opacity: 0.6;
}

.status-message-pending {
  background-image: url(../../../images/waiting-icon.png);
  height: 35px;
  width: 35px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 1%;
  -webkit-animation:spin 5s linear infinite;
  -moz-animation:spin 5s linear infinite;
  animation:spin 5s linear infinite;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.status-message-failed {
  background-image: url(../../../images/fail-icon.png);
  height: 30px;
  width: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 1%;
  background-color: transparent !important;
  border-color: transparent !important;
}

.message-error-tooltip p {
  margin: 0;
}

.schedule-msg__btn {
  width: 128px  !important;
}

.save-scheduled-msg-btn {
  background-color: #d45e36;
}

.conversation-scheduled-msg-input {
  display: flex;
  justify-content: space-between;
}

.conversation-scheduled-msg__choose-itinerary {
  margin-bottom: 3%;
}

.conversation__templates {
  display: flex;
}
