.itineraries-search-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  background-color: #f9fafb;
}

.itineraries-search-bar__tab {
  width: auto;
  padding: 15px 32px 15px 50px;
  background-color: #f9fafb;
  border: none;
  color: #868691;
  text-align: center;
  font-size: 20px;
  font-weight: 900;
  line-height: 25px;
}

.itineraries-search-bar--is-highlight {
  width: auto;
  padding: 15px 32px 15px 50px;
  background-color: #f9fafb;
  border: none;
  border-bottom: 4px solid #f38251;
  color: #363648;
  font-size: 20px;
  font-weight: 900;
  line-height: 25px;
  text-align: center;
}

.itineraries-search-bar__search {
  display: flex;
}

.itineraries-search-bar__select {
  width: 136px;
  margin-left: 10px;
  color: #363648;
  text-indent: 5px;
  font-size: 12px;
}

.itineraries-search-bar__input {
  width: 100px;
}
