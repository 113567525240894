.timeline {
  display: flex;
  list-style: none;
  overflow: auto;
  max-width: 70vw;
  min-height: 140px;
  margin: 0px;
  padding: 0px 10px 10px 10px;
  border-radius: 7px;
  background: white; 
}

.timeline::-webkit-scrollbar {
  background: #f2f7fa;
  border-radius: 7px;
  height: 8px;
}

.timeline::-webkit-scrollbar-track {
  background: #ddedf6;
  border-radius: 7px;
}

.timeline::-webkit-scrollbar-thumb {
  background: #b7daed;
  border-radius: 7px;
}

.timeline::-webkit-scrollbar-thumb:hover {
  background: #90c6e4;
  border-radius: 7px;
}

.timeline__journey-day-list-item {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  width: 10.5vw;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-left: none;
}

.timeline__journey-day-list-item--inactive {
  background-color: #e0e1e2;
}

.timeline__journey-day-header{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  margin: 0 auto;
}

.timeline__journey-day-header-day{
  grid-column: 2/3;
}

.timeline__btn-container {
  grid-column: 3/4;
  margin: 2px 5px;
  display: flex;
  justify-content: flex-end;
}

.timeline__btn {
  width: 22px;
  height: 22px;
  border: none;
  border-radius: 7px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.08);
  margin: 2px;
}

.timeline__btn--add {
  background: #90c6e4;
  background-image: url("../../../images/addWhatsapp.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px;
}

.timeline__btn--add:hover {
  opacity: 0.8;
}

.timeline__btn--delete {
  background: #f38251;
  background-image: url("../../../images/deleteIcon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px;
}

.timeline__btn--delete:hover {
  opacity: 0.8;
}

.timeline__date {
  font-size: 12px;
  text-align: center;
  color: #86869f;
  justify-content: flex-start;
}

.timeline__date--today {
  border-bottom: 3px solid #f38251;
  margin-bottom: -3px;
  font-weight: 900;
  color: #363648;
}

.timeline__journey-day-header {
  text-align: center;
  margin: 2px 0;
}

.timeline__message {
  color: #363648;
  font-size: 10px;
  padding: 10px;
  margin: 3px 3px;
  border-radius: 7px;
  line-height: 14px;
  background: #ddedf6;
  overflow: hidden;
}


.timeline__journey-day-location,
.timeline__journey-day-no-location,
.timeline__journey-day-location-clickable,
.timeline__journey-day-accommodation,
.timeline__journey-day-no-accommodation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 12px;
  color: #4d4d53;

  /* background-color: #a5c1c2; */
  margin: 2px;
  padding: 5px;
}


/* .timeline__journey-day-accommodation, .timeline__journey-day-location, .timeline__journey-day-location-clickable, .timeline__journey-day-no-accommodation, .timeline__journey-day-no-location {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 12px;
  color: #4D4D98;
  background-color: #a5c1c2;
  margin: 2px;
  padding: 5px;
} */

.timeline__journey-day-location-clickable:hover {
  opacity: 0.8;
  cursor: pointer;
}

#red {
  background-color: #f59586;
}

#yellow {
  background-color: #ffd166;
}

#timeline__journey-day-accommodation--1{
  background-color: #FACAC3;
}

#timeline__journey-day-accommodation--2{
  background-color: #FFE8B3;
}
#timeline__journey-day-location{
  background-color: #7ad5f1;
  font-weight: bold;
  text-align: center;
}
