.individual-traveller-container {
  min-height: 185px;
}

.individual-traveller-container__details {
  display: grid;
  grid-template-columns: 32% 32% 18% 18%;
  grid-auto-rows: minmax(50px, auto);
}

.individual-traveller-container__first-last-name {
  display: flex;
  flex-direction: column;
}

.individual-traveller-container__title {
  color: #868691;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0px;
}

.individual-traveller-container__title:first-child {
  margin-right: 15px;
}

#individual-traveller-container__full_name.form-control,
#individual-traveller-container__birthday.form-control,
#individual-traveller-container__email.form-control,
#individual-traveller-container__phone-number.form-control {
  width: 180px;
  height: 20px;
  font-size: 12px;
  color: #8688a3;
}

.individual-traveller-container__data {
  color: #363648;
  font-size: 14px;
}

.individual-traveller-container__container {
  height: 40px;
  width: 90px;
  border: none;
  border-radius: 7px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  text-align: center;
}

.individual-traveller-container__container--prev-trips {
  margin-right: 10px;
}
.individual-traveller-container__container--prev-trips:hover {
  background: #ddedf6;
}

.individual-traveller-container__preference-container {
  grid-column: 2/5;
  grid-row: 1/3;
  padding: 5px 5px 0px 5px;
  border-radius: 7px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}

.individual-traveller-container__phone-number {
  grid-row: 2/3;
}
