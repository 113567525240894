.logout__title.modal-title {
  margin: 15px;
  border-bottom: 4px solid #f38251;
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  line-height: 28px;
}

.logout__btn.modal-body {
  display: flex;
  justify-content: center;
}
