.yellow {
  background-color: #ffb300;
  color: white;
}

.red {
  background-color: #ee4e36;
  color: white;
}

.green {
  background-color: #0fa484;
  color: white;
}

.blue {
  background-color: #90c6e4;
  color: white;
}

.orange {
  background-color: #f38251;
  color: white;
}

.pink {
  background-color: #d66969;
  color: white;
}
