.add-traveller-to-itinerary__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 400px;
}

.add-traveller-to-itinerary__input {
  width: 415px;
  margin: 0px;
  font-size: 14px;
  text-indent: 10px;
}

.add-traveller-to-itinerary__btn-container {
  height: 350px;
  width: 452px;
  overflow: auto;
  margin-top: 10px;
}

.add-traveller-to-itinerary__btn-container::-webkit-scrollbar {
  background: #f2f7fa;
  border-radius: 7px;
  width: 8px;
}

.add-traveller-to-itinerary__btn-container::-webkit-scrollbar-track {
  background: #ddedf6;
  border-radius: 7px;
}

.add-traveller-to-itinerary__btn-container::-webkit-scrollbar-thumb {
  background: #b7daed;
  border-radius: 7px;
}

.add-traveller-to-itinerary__btn-container::-webkit-scrollbar-thumb:hover {
  background: #90c6e4;
  border-radius: 7px;
}

.add-traveller-to-itinerary__btn {
  width: 432px;
  margin: 7px 0px;
  background-color: #f3f9fc;
  color: #363653;
}

.add-traveller-to-itinerary__btn:hover {
  background-color: #e8f6fd;
  color: #363653;
}
