.agent__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
  
  .agent__icon {
    width: 60px;
    padding: 10px;
  }
  
  .agent__information {
    background-color: white;
    border: none;
    font-size: 14px;
  }
  
  .agent__name {
    color: #363648;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 4px;
  }
  
  .agent__type {
    color: #868691;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    margin: 4px;
  }
  
  .agent__mark-as-complete,  .agent__unassign-task {
    border-radius: 6px;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.08);
    border: none;
    color: white;
    font-size: 12px;
    padding: 5px;
    width: 70%; 
    margin-left: 3vw;
  }

  .agent__mark-as-complete{
    background-color: #020360;
    margin-bottom: 5px;
  }

  .agent__mark-as-complete:hover{
    background-color: #020360;
    opacity: 0.8;
  }

  .agent__unassign-task {
    background-color: #6768A0;
  }

  .agent__unassign-task:hover {
    background-color: #45466e;
  }