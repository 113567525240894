.roles-permissions {
    box-sizing: border-box;
    padding: 30px 30px 70px 30px;
    background-color: #f9fafb;
}

#roles_permissions-tab-roles_permissions,
#roles_permissions-tab-users {
    width: 50%;
    text-align: center;
    color: #868691;
    font-size: 19px;
    font-weight: 900;
    line-height: 25px;
    padding: 8px 12px;
    border: none;
}

#roles_permissions-tab-roles_permissions.active,
#roles_permissions-tab-users.active {
    color: #363648;
    font-size: 20px;
    font-weight: 900;
    line-height: 25px;
    border: none;
    background-color: #F9FAFB;
    border-bottom: 4px #f38251 solid;
}

.btn {
    border: none;
    border-radius: 4px;
    padding: 4px 8px;
    margin: 3px;
}

.add-role {
    margin-bottom: 30px;
}

.role-settings {
    padding: 2px 4px !important;
}

.permission-block {
    background-color: #80c1e4;
    padding: 2px 4px;
    display: inline-block;
    border-radius: 5px;
    margin: 5px;
}

.th-title {
    width: 20%;
}

.th-permissions {
    width: 60%;
}

.th-companies {
    width: 20%;
}

.th-roles {
    width: 40%;
}

.create-edit-page-title {
    background-color: #f9fafb;
    color: #868691;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 1px;
    font-size: 26px;
    margin-bottom: 30px;
}

.form-block {
    margin-bottom: 20px;
}

.form-block > p {
    margin-bottom: 5px;
    font-size: 16px;
}

.roles-form {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.roles-form__title {
    color: #868691;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
}