.auto-complete-location{
    display:flex;
    flex-direction: row;
  }

  .auto-complete-location__search-btn{
    height: 38px;
    width: 38px;
    background-image: url("../images/Search@2x.png");
    background-color: #ddedf6;
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: center;
    border: solid 1px #c6c6c6;
    border-left: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-right: 15px;
  }

  .auto-complete-location__clear-all-filters-btn {
    height: 38px;
    width: 38px;
    background-image: url("../images/deleteIcon.png");
    background-size: 12px;
    background-color: #ddedf6;
    background-repeat: no-repeat;
    background-position: center;
    border: solid 1px #c6c6c6;
    border-radius: 4px;
  }
  