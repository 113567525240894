.itinerary-header {
  display: flex;
  justify-content: space-between;
}

.itinerary-header__title {
  display: flex;
  align-items: center;
  color: #363648;
  font-size: 20px;
  font-weight: 900;
}

.itinerary-header__ind-title {
  display: flex;
  align-items: center;
}

.itinerary-header__full-name,
.itinerary-header__dossier-id {
  margin-left: 20px;
}

.itinerary-header__tag {
  margin-left: 15px;
  padding: 0px 12px;
  opacity: 0.7;
  border-radius: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.itinerary-header__checked-btn,
.itinerary-header__unchecked-btn {
  width: 200px;
  margin-right: 10px;
}

.itinerary-header__unchecked-btn {
  background-color: #ee4e36;
}

.itinerary-header__unchecked-btn:hover {
  background-color: #b3250f;
}

.itinerary-header__status {
  margin-left: 45px;
}

.itinerary-header__status-title {
  width: 90px;
  height: 21px;
  margin: 3px;
  border-radius: 7px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  font-size: 14px;
  text-align: center;
}
