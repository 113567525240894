.search-input-field {
  display: flex;
}

.search-input-field__input {
  height: 37px;
  padding: 9px;
  margin-left: 15px;
  border: solid 1px #c6c6c6;
  border-right: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-size: 12px;
}

.search-input-field__btn {
  height: 37px;
  width: 37px;
  background-image: url("../../../images/Search@2x.png");
  background-color: #ddedf6;
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: center;
  border: solid 1px #c6c6c6;
  border-left: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}


