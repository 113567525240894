.tip-row:nth-child(even) {
  background: #f3f9fd;
}

.tip-row:nth-child(even):hover {
  background: #ddedf6;
}

.tip-row:nth-child(odd):hover {
  background: #dae5eb;
}

.tip-row__btn {
  width: 100%;
  display: grid;
  grid-template-columns: 5% 23% 34% 25%;
  grid-auto-rows: minmax(12px, auto);
  grid-gap: 15px;
  grid-template-areas: "tip-row__type tip-row__name tip-row__message tip-row__tags";
  margin: 0 auto;
  padding: 6px 10px;
  border: none;
  background: transparent;
}

.tip-row__type {
  grid-area: tip-row__type;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 25px;
  max-width: 50px;
}

.tip-row__name {
  grid-area: tip-row__name;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  margin: auto 0px;
  text-overflow: ellipsis;
  color: #363648;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}

.tip-row__message {
  grid-area: tip-row__message;
  white-space: nowrap;
  margin: auto 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #868691;
  text-align: left;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
}

.tip-row__tags {
  grid-area: tip-row__tags;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
}

.tip-row__tag {
  margin: 4px;
  max-height: 14px;
  white-space: nowrap;
  padding: 1px;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  background-color: white;
  color: #5f5f68;
  font-size: 8px;
  line-height: 14px;
}
