.avatar {
    height: 20px;
    width: 20px;
}

.preferences > p {
    margin: 0px;
}

.info-block > p {
    margin: 0px;
    font-size: 14px;
}

.info-block-long-text > p {
    overflow-wrap: anywhere;
}

.tips-question-title, .tips-question-count {
    font-size: 12px;
    width: min-content;
    margin: auto !important;
}

.info-block {
    line-height: 1;
}

.profile-container {
    max-width: inherit;
}

.profile-container > .row {
    margin: 20px 10px;
}

.profile-container, .profile-container > .row > div {
    padding: 0px !important;
}

.info-block-title {
    font-weight: 600;
    color: #8f9099;
}

.tips-question-block {
    margin-right: 5px;
    font-size: 12px;
    padding: 5px;
    background-color: white;
    color: #363648;
    font-size: 12px;
    border-radius: 5px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    cursor: pointer;
}

.tips-question-count {
    font-size: 16px;
    font-weight: 600;
    color: black;
    width: min-content;
    margin: auto;
}

.add-preference > button, .add-preference > button:hover {
    cursor: pointer;
    color: black;
    text-decoration: none;
    border: none;
    padding: 0px 4px;
    background-color: #e6e6e8;
}

.add-preference > button::after {
    content: none;
}

.dropdown-menu > input {
    font-size: 12px;
}

.dropdown-menu {
    max-width: 210px!important;
}

.preferences-item {
    background-color: #e6e6e8;
    color: #363648;
    font-weight: 600;
    border: 1px solid #e6e6e8;
    border-radius: 10px;
    padding: 0px 3px;
    margin-right: 5px;
    font-size: 10px;
    white-space: pre;
}

.delete-preference, .delete-preference:hover {
    color: #363648;
    cursor: pointer;
    text-decoration: none;
    border: none;
    padding: 0px 4px;
    background-color: #e6e6e8;
}

.modal-title {
    border-bottom: 2px solid #f38251;
    font-size: 20px;
    font-weight: 600;
}

.modal-header > button {
    width: 30px !important;
    height: 30px !important;
    background: #ddedf6 !important;
    border-radius: 50% !important;
}

.modal-header .close {
    padding: 0px !important;
    margin: 0px !important;
}

.close,
.close:active,
.close:focus {
    outline: none;
}

.modal-header {
    border: none;
}

.preference-icon {
    height: 7px;
    width: 7px;
}

.list-unstyled {
    height:250px;
    overflow:scroll;
    overflow-x:hidden;
}

.edit-name-profile{
    width: 85px;
    height: 25px;
}

.text-edit-name {
    min-height: 50px;
}