#active-task-container__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px;
  background-color: #f9fafb;
}

.task-container__sorting-options {
  display: grid;
  grid-template-columns: 10% 35% 20% 17% 17%;
  width: 100%;
  column-gap: 0px;
  align-items: center;
  margin: 0 10px 5px 10px;
  background-color: #f9fafb;
}

.task-container__filter-phone{
  display:flex;
  justify-content: flex-end;
}

.task-container__filter-date{
  display:flex;
  justify-content: flex-end;
  margin-right: 5px;
}

.task-container__filter-date input {
  color: #c6c6c6;
}

.inbox-search-bar__clear-filter-btn {
  height: 37px;
  width: 37px;
  margin-left: 15px;
  background-image: url("../../../images/deleteIcon.png");
  background-size: 12px;
  background-color: #DDEDF6; 
  background-repeat: no-repeat;
  background-position: center;
  border: solid 1px #c6c6c6;
  border-radius: 4px;
}

#task-container__header--active {
  margin: 0px;
  background-color: #f9fafb;
  color: #868691;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 1px;
}

.task-container__filter-status-select,
.task-container__filter-phone-select,
.task-container__filter-branch-select {
  font-size: 12px;
}

.task-container__filter-status-select {
  margin-right: 5px;
}

.task-container__filter-branch {
  display: flex;
  margin-right: 5px;
}
.task-container__filter-branch-select {
  min-width: 78%;
}
.task-container__filter-text {
  color: #525258;
  font-size: 12px;
  background-color: #f9fafb;
  border: none;
}

.task-container__tasks {
  background-color: #f9fafb;
}

.task {
  display: grid;
  grid-template-columns: 12% 14% 14% 14% 12% 12%;
  column-gap: 4%;
  width: 100%;
  padding: 8px;
  margin: 8px;
  border-radius: 7px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
}

#active-task-container--dropzone1,
#task-container--dropzone2 {
  width: 100%;
  height: 400px;
  margin: 5px 0px 5px 0px;
  border-radius: 3px;
  background-color: #f9fafb;
}

.tasks__table-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: sticky;
  bottom: 0;
}

.tasks-unassigned__table-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  bottom: 0;
}

.tasks__table-btn--previous {
  width: 40px;
  height: 40px;
  margin: 5px 15px;
  border: none;
  background: url(../../../images/ButtonCircle@4x.png) center;
  background-size: 40px;
}

.tasks__table-btn--next {
  width: 40px;
  height: 40px;
  margin: 5px 15px;
  border: none;
  background: url(../../../images/ButtonCircle@4x.png) center;
  background-size: 40px;
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(9180deg);
  transform: rotate(180deg);
}

.tasks__table-btn--previous:disabled,
.tasks__table-btn--next:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.tasks__table-page-number {
  font-size: 12px;
}

.search-btn-branch{
  min-width: 17%;
}

.total-title {
  color: #868691;
}
