.task-list {
  color: #868691;
  font-size: 12px;
  letter-spacing: 0px;
  cursor: pointer;
}

.task-list__user{
  padding: 3px 8px;
  border-radius: 15px;
  margin: 0px 5px 0px 0px;
  opacity: 0.7;
}

.task-list__task-related-to{
    display:flex;
    padding: 2px 3px 15px 0px; 
    color: #363648;
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
}

.task-list__related-to{
  padding-right: 3px;
  font-weight: 900;
}

